<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" md="4">
        <v-card
          class="rounded-pill border EoleBlue ml-10 d-flex"
          width="450"
          height="60"
          v-if="this.customerTypology === 'professionnel'"
          style="margin-top: -45px"
        >
          <v-row class="align-center justify-center">
            <v-col cols="12" md="2">
              <i class="fa-regular fa-building white--text ml-7" style="font-size: 1.7rem"></i>
            </v-col>
            <v-col cols="12" md="10">
              <v-card-text class="text-h5 white--text d-flex align-center no-padding ml-5">
                {{ this.customers.name }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <i v-bind="attrs" v-on="on" class="fa-solid fa-crown EoleYellow--text ml-5"></i>
                  </template>
                  <span>Client VIP</span>
                </v-tooltip>
                <v-dialog v-model="dialogActiveSponsor" max-width="1000px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Activer partenaire</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pa-5" v-if="customers.program_activated === 1">
                      <v-container>
                        <h3>Êtes-vous sûr de vouloir Activer le programme de parainnages ?</h3>
                      </v-container>
                    </v-card-text>

                    <v-card-text v-else>
                      <v-container>
                        <h3>Êtes-vous sûr de vouloir désactiver le programme de parainnages ?</h3>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="dialogActiveSponsor = false" class="EoleYellow--text" text> Annuler</v-btn>
                      <v-btn
                        v-if="customers.program_activated === 1"
                        color="EoleGreen"
                        class="white--text"
                        @click="putCustomer({ idProfessionnal: customers.id, idState: 0 })"
                      >
                        <v-icon left>mdi-check</v-icon>Activer le programme de parrainage
                      </v-btn>
                      <v-btn v-else color="EoleError" class="white--text" @click="changeSponsorState(customers.id, 1)">
                        Désactiver le programme de parrainage
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                      <i
                        v-bind="attrs"
                        v-on="on"
                        :class="[
                          'fa-regular fa-handshake',
                          customers.program_activated === 1 ? 'EoleGray--text' : 'EoleYellow--text',
                          'ml-5',
                        ]"
                        style="font-size: 1.7rem"
                        @click="dialogActiveSponsor = true"
                      ></i>
                    </v-btn>
                  </template>
                  <span>Activer/Désactiver le parrainage</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <i
                      v-bind="attrs"
                      v-on="on"
                      class="fa-solid fa-lock EoleGray--text ml-5"
                      v-if="locked === false"
                      @click="changeLock(true)"
                    ></i>
                    <i
                      v-bind="attrs"
                      v-on="on"
                      class="fa-solid fa-lock-open EoleYellow--text ml-5"
                      v-else
                      @click="changeLock(false)"
                    ></i>
                  </template>
                  <span>Activer/Désactiver accès client.</span>
                </v-tooltip>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-else>
          <v-card-title>PARTICULIER : Mathis GRANNEC</v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <v-row class=""> </v-row>
      </v-col>
    </v-row>

    <br />
    <v-row>
      <v-col cols="12" md="11">
        <v-row>
          <v-card-text v-if="this.customerTypology === 'professionnel'">
            <i class="fa-solid fa-user EoleBlue--text mr-2"></i>{{ this.mainInterlocutor.nameInterlocutor }}
            <i class="fa-solid fa-phone EoleGreen--text mr-2 ml-5"></i>{{ this.customers.phone }}
            <i class="fa-solid fa-envelope mr-2 ml-5 EoleBlue--text" @click="showEmail()"></i>{{ this.customers.mail }}
            <i class="fa-solid fa-location-dot mr-2 ml-5 EoleYellow--text" @click="showMap()"></i
            >{{ this.customers.address }}, {{ this.customers.city }} ({{ this.customers.postalCode }})
            <i class="fa-solid fa-circle-info EoleBlue--text mr-2 ml-5"></i>Client : {{ this.customers.siret }}
            <i class="fa-solid fa-circle-info EoleBlue--text mr-2 ml-5" v-if="this.customers.idCustomerFranchise"></i
            ><i v-if="this.customers.idCustomerFranchise">Franchisé de {{ this.customers.nameCustomerFranchise }}</i>
          </v-card-text>
          <div></div>
        </v-row>
      </v-col>
      <v-col cols="12" md="1" class="text-right">
        <v-btn class="text-h9" elevation="0" @click=";(expand = !expand), moreInfo()">
          <i class="fa-solid fa-chevron-down fa-1x" v-bind:style="{ transform: rotation }"></i>
        </v-btn>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-card v-show="expand" outlined class="pa-5" v-if="this.customerTypology === 'professionnel'">
        <v-row style="margin-bottom: -50px">
          <v-col cols="12" md="4">
            <v-card-text class="text-h6"><b>Information général</b></v-card-text>
            <v-card-text> <v-icon class="mr-3">mdi-information</v-icon><b>Code IDCC :</b> Non renseigné </v-card-text>
            <v-card-text> <v-icon class="mr-3">mdi-information</v-icon><b>Nb salariés :</b> 15 </v-card-text>
            <v-card-text class="mt-n4">
              <v-switch label="Mode franchiseur" inset v-model="franchise" @click="switchFranchise"> </v-switch>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="4">
            <v-card-text class="text-h6"><b>Information général</b></v-card-text>
            <v-card-text> <v-icon class="mr-3">mdi-segment</v-icon><b>Segment :</b> Non renseigné </v-card-text>
            <v-card-text>
              <v-icon class="mr-3">mdi-information</v-icon><b>Code NAF - APE :</b> Activités des agents et courtiers
              d'assurances (66.22Z)
            </v-card-text>
          </v-col>
          <v-col cols="12" md="4">
            <v-card-text class="text-h6"><b>Conseillers</b></v-card-text>
            <v-card-text> <v-icon class="mr-3">mdi-account</v-icon><b>Commerce :</b> David AUDREN </v-card-text>
            <v-card-text class="mb-3"> <v-icon class="">mdi-phone</v-icon> 02 35 69 89 69 </v-card-text>

            <v-card-text> <v-icon class="mr-3">mdi-account</v-icon><b>Sinistre :</b> Eric LE BIHAN </v-card-text>
            <v-card-text class="mb-3"> <v-icon>mdi-phone</v-icon> 02 31 53 98 98 </v-card-text>

            <v-card-text>
              <v-icon class="mr-3">mdi-account</v-icon><b>Comptabilité :</b> Philippe LE BIHAN
            </v-card-text>
            <v-card-text class="mb-3"> <v-icon>mdi-phone</v-icon> 02 35 12 97 92 </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="4"> </v-col>
        </v-row>
      </v-card>
      <v-card v-show="expand" outlined class="pa-5" v-else>
        <v-row>
          <v-col>
            <v-card-text>TEXT pour particulier</v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>
  </v-container>
</template>

<script>
import Vue from "vue"
import Router from "vue-router"
import config from "@/views/config/config"
import "vue-loading-overlay/dist/vue-loading.css"

Vue.use(Router)

export default {
  props: ["customerId", "customerTypology"],

  mounted() {
    this.$watch("customerId", this.fetchListCustomerProfessional())
  },

  data: () => ({
    dataPappers: false,
    pappersInformations: [],
    expand: false,

    dialogActiveSponsor: false,
    handshake: false,
    locked: false,

    franchise: false,

    company: {
      idProfessionnal: null,
      sponsorCode: null,
      reduction: null,
    },

    rotation: "rotate(0deg)",

    globalInfo: [
      {
        id: 1,
        phoneInterlocutor: "02 35 65 98 78",
        mailInterlocutor: "20 213 000,56" + "€",
        description: "Tendance Chiffre d'affaires",
      },
    ],

    mainInterlocutor: {
      nameInterlocutor: "",
    },

    customers: {
      id: "",
      name: "",
      siret: "",
      mail: "",
      phone: "",
      address: "",
      city: "",
      postalCode: "",
      typology: "",
      idCustomerFranchise: null,
      nameCustomerFranchise: null,
    },
  }),
  methods: {
    changeLock(value) {
      this.locked = value
    },

    async switchFranchise() {
      let numFranchise = 0
      if (this.franchise === true) {
        numFranchise = 1
      }
      const idAgency = this.$store.state.user.agency_id
      let changeFranchise = "activeFranchise"
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/customer_professional.php?place=${changeFranchise}&idAgency=${idAgency}&idProfessional=${this.customers.id}&franchise=${numFranchise}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )
        console.log("ok2")
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nLors de l'activation de franchise.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
      window.location.reload()
    },

    changeSponsorState(id, stateId) {
      switch (stateId) {
        case 0:
          this.dialogActiveSponsor = true
          break
        case 1:
          this.putCustomer({ idProfessionnal: id, idState: 1 })
          break
      }
    },

    async putCustomer(data) {
      const idAgency = this.$store.state.user.agency_id
      console.log("data : ", data.idState)
      console.log("data id : ", data.idProfessionnal)
      let place = "activeProgram"

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional?idAgency=${idAgency}&idProfessional=${data.idProfessionnal}&programActivated=${data.idState}&place=${place}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )

        if (response.status === 400) {
        } else {
          this.$toast.success(`Le client ${this.company.name} à bien été ajouté.`, {
            position: "bottom-right",
            timeout: 5000,
          })
          window.location.reload()
        }
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },

    generateSponsorCode(length) {
      // generate code with 12 characters and have numbers, letters, uppercase and lowercase, and special characters
      // let code = Math.random().toString(36).slice(2)
      // this.company.sponsorCode = code
      // this.$forceUpdate()
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      let code = Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map(value => characters[value % characters.length])
        .join("")

      return code
    },

    setCode() {
      this.company.sponsorCode = this.generateSponsorCode(12)
      this.$forceUpdate()
    },

    moreInfo() {
      if (this.rotation === "rotate(0deg)") {
        this.rotation = "rotate(180deg)"
      } else {
        this.rotation = "rotate(0deg)"
      }
    },

    showMap() {
      let routeData = this.$router.resolve({
        name: "https://www.google.fr/maps/place/1 Av. des Prunus, 35270 Combourg",
      })
      window.open(routeData.location.name, "_blank")
    },
    showEmail() {
      let emailTo = "mathis.grannec@eole-assurance.bzh"
      location.href = "mailto:" + emailTo
      window.open(location.href)
    },

    async fetchInterlocutor() {
      let agencyId = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customerId}/customer_interlocutor`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        const data = await response.json()

        this.mainInterlocutor = []
        data.Interlocutor.forEach(element => {
          if (element.main_interlocutor === 1) {
            this.mainInterlocutor.nameInterlocutor = element.name
          }
        })

        this.$toast.info(`Affichage des Interlocuteurs.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      } catch (error) {
        this.$toast.error(`Impossible de charger les éléments interlocuteurs.`, {
          position: "bottom-right",
          timeout: 5000,
        })
        console.log(error)
      }
    },

    async fetchListCustomerParticular() {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/customer_particular`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        this.customers = []
        data.forEach(element => {
          this.customers.push({
            id: element.id,
            name: element.lastname + " " + element.firstname,
            mail: element.email,
            phone:
              element.phone.substring(0, 2) +
              " " +
              element.phone.substring(2, 4) +
              " " +
              element.phone.substring(4, 6) +
              " " +
              element.phone.substring(6, 8) +
              " " +
              element.phone.substring(8),
            address: element.address,
            typology: element.typology,
            program_activated: element.program_activated,
          })
        })

        console.log(this.customers)
        this.$toast.info(`Affichage de la liste des clients.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      } catch (e) {
        this.$toast.error(`Impossible de charger la liste des client.`, {
          position: "bottom-right",
          timeout: 5000,
        })
        console.log(e)
      }
    },

    async fetchListCustomerProfessional() {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customerId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.customers.id = element.id
          this.customers.name = element.commercial_name
          this.customers.siret = element.siret
          this.customers.mail = element.email
          ;(this.customers.phone =
            element.phone.substring(0, 2) +
            " " +
            element.phone.substring(2, 4) +
            " " +
            element.phone.substring(4, 6) +
            " " +
            element.phone.substring(6, 8) +
            " " +
            element.phone.substring(8)),
            (this.customers.address = element.address)
          this.customers.city = element.city
          this.customers.postalCode = element.postal_code
          this.customers.typology = element.typology
          this.customers.franchise = parseInt(element.franchise)
          this.customers.program_activated = element.program_activated
          this.customers.idCustomerFranchise = element.id_customer_franchise
        })

        console.log(this.customers)

        this.$toast.info(`Affichage de la liste des clients.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      } catch (e) {
        this.$toast.error(`Impossible de charger la liste des client.`, {
          position: "bottom-right",
          timeout: 5000,
        })
        console.log(e)
      }
      if (this.customers.franchise === 1) {
        this.franchise = true
      }
      this.fetchInterlocutor()
      if (this.customers.idCustomerFranchise) {
        this.fetchNameFranchiseur()
      }
    },

    async fetchNameFranchiseur() {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customers.idCustomerFranchise}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        const data = await response.json()

        data.forEach(elem => {
          this.customers.nameCustomerFranchise = elem.commercial_name
        })
      } catch (e) {
        console.log("error for fetch name franchise")
      }
    },
  },
}
</script>

<style>
/* .clientBanner {
    background-image: url("../../../../../src/assets/images/profils/banner.png");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  } */

.dot {
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

/* .flip-enter-active,
  .flip-leave-active {
    transition: transform 0.5s;
  }

  .flip-enter,
  .flip-leave-to {
    transform: rotate(90deg);
  } */
</style>
