<template>
  <v-card outlined width="1500">
    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
      <v-icon class="mr-2" color="white">mdi-account</v-icon> Renseignement de société
    </v-card-title>
    <v-card-text class="pa-5">
      <v-text-field
        :error="siretNotFound"
        :error-messages="siretNotFoundMessage"
        type="number"
        counter="14"
        outlined
        v-model="siret"
        label="Entrez votre n° de siret"
        prepend-inner-icon="mdi-briefcase"
      >
      </v-text-field>
    </v-card-text>
    <v-row>
      <v-col v-if="!dataPappers" class="text-right" cols="12" md="10">
        <v-btn outlined @click="dataPappers = true">
          <v-icon class="mr-3">mdi-information</v-icon>Faire une saisie classique
        </v-btn>
      </v-col>
      <v-col class="text-center" cols="12" md="2" v-if="!dataPappers">
        <v-btn color="primary" @click="fetchPappers(siret)">recherche</v-btn>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="dataPappers">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                v-model="company.nameCompany"
                label="Nom de la société"
                prepend-inner-icon="mdi-domain"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                v-model="company.phone"
                label="Numéro de téléphone"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                v-model="company.email"
                label="Email"
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                v-model="company.postalCode"
                label="Code Postale"
                prepend-inner-icon="mdi-map-marker"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                v-model="company.address"
                label="Addresse"
                prepend-inner-icon="mdi-map-marker"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                v-model="company.city"
                label="Ville"
                prepend-inner-icon="mdi-map-marker"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title v-if="dataPappers" class="EoleBlue" style="color: white !important; font-size: 1rem">
      <v-icon class="mr-2" color="white">mdi-information</v-icon> Description de l'activité de
      {{ company.nameCompany }}
    </v-card-title>

    <v-card-text v-if="dataPappers" class="pa-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            v-model="company.codeNaf"
            label="Code NAF/APE"
            prepend-inner-icon="mdi-briefcase"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            v-model="company.libelleNaf"
            label="Description de l'activité"
            prepend-inner-icon="mdi-briefcase"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title v-if="dataPappers" class="EoleBlue" style="color: white !important; font-size: 1rem">
      <v-icon class="mr-2" color="white">mdi-account-tie</v-icon> A propos des dirigeants
    </v-card-title>
    <v-card-text v-if="dataPappers" class="no-padding">
      <v-data-table
        v-model="signatory"
        :headers="headersDirectors"
        :items="company.representants"
        hide-default-footer
        no-data-text="Aucun dirigeant ajouté. (Cliquez sur le bouton ci-dessous pour ajouter un dirigeant à la liste)"
      >
        <template v-slot:[`item.checkbox`]="{ item }">
          <v-checkbox
            :disabled="item.nom === '' || item.prenom_usuel === ''"
            :color="getCheckboxColor()"
            v-model="signatory"
            :value="{
              civility: item.sexe === 'M' ? 'M' : 'Mme',
              name: item.nom + ' ' + item.prenom_usuel,
            }"
            :key="item.nom"
          >
          </v-checkbox>
        </template>
        <template v-slot:[`item.sexe`]="{ item }">
          <v-select class="mt-5" dense :value="item.sexe" :items="['M', 'Mme']" prepend-inner-icon="mdi-account">
          </v-select>
        </template>

        <template v-slot:[`item.nom`]="{ item }">
          <v-text-field class="mt-5" dense v-model="item.nom" prepend-inner-icon="mdi-account"> </v-text-field>
        </template>
        <template v-slot:[`item.prenom_usuel`]="{ item }">
          <v-text-field class="mt-5" dense v-model="item.prenom_usuel" prepend-inner-icon="mdi-account"></v-text-field>
        </template>
        <template v-slot:[`item.loisir`]="{ item }">
          <v-text-field class="mt-5" dense v-model="item.loisir" prepend-inner-icon="mdi-account"></v-text-field>
        </template>
      </v-data-table>
      <v-row class="px-2 my-2">
        <v-btn block text elevation="0" @click="addDirector">
          <v-icon left>mdi-plus</v-icon> Ajouter un dirigeant
        </v-btn>
      </v-row>
    </v-card-text>

    <br />
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn @click="lastStep(2)" elevation="0" v-if="!dataPappers">
        <v-icon left> mdi-chevron-left </v-icon>
        <span>Précédent</span>
      </v-btn>
      <v-btn @click="cancelSaisie" elevation="0" v-if="dataPappers">
        <v-icon left> mdi-chevron-left </v-icon>
        <span>Précédent</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="addCustomerPro" outlined v-if="dataPappers">
        <i class="fa-solid fa-user-plus mr-2"></i>
        <span>Ajouter</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import config from "@/views/config/config"

export default defineComponent({
  data: () => ({
    siretNotFound: false,
    siretNotFoundMessage: "",
    siret: null,
    dataPappers: false,
    pappersInformations: [],
    signatory: [],

    headersDirectors: [
      {
        text: "Civilité",
        align: "start",
        sortable: false,
        value: "sexe",
        width: "10%",
      },
      {
        text: "Nom",
        align: "start",
        sortable: false,
        value: "nom",
        width: "10%",
      },
      {
        text: "Prénom",
        align: "start",
        sortable: false,
        value: "prenom_usuel",
        width: "10%",
      },
      {
        text: "Loisir",
        align: "start",
        sortable: false,
        value: "loisir",
        width: "10%",
      },
    ],

    company: {
      postalCode: null,
      address: null,
      city: null,
      nameCompany: null,
      siret: null,
      codeNaf: null,
      libelleNaf: null,
      email: null,
      phone: null,
      representants: [],
    },
  }),

  setup() {},

  methods: {
    nextStep() {
      this.$emit("nextStepHome", 3)
    },
    lastStep() {
      this.$emit("lastStepProPart", 3)
    },

    cancelSaisie() {
      this.company = {
        postalCode: null,
        address: null,
        city: null,
        nameCompany: null,
        siret: null,
        codeNaf: null,
        libelleNaf: null,
        representants: [],
      }
      this.dataPappers = false
    },

    addDirector() {
      this.company.representants.push({
        nom: "",
        prenom_usuel: "",
        prenom: "",
      })
    },

    getCheckboxColor() {
      // verify if item is in the array of checked items and return the color
      // patern is : [{"civility": "M", "name":"FRICHOT ROMAN"}]

      return this.signatory.includes() ? "EoleGreen" : ""
    },

    async fetchPappers(siret) {
      console.log("reponse")
      this.pappersInformations = []
      this.loadingData = true
      // let siret = "53859143900046"

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siret=${siret}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )

        if (response.status === 400) {
          this.siretNotFound = true
          this.siretNotFoundMessage = "Impossible de trouver le siret que vous avez renseigné, merci de réessayer"
        } else {
          console.log("reponse2")
          this.siretNotFound = false
          this.siretNotFoundMessage = ""

          let data = await response.json()
          this.pappersInformations.push(data)

          if (data.representants[0].personne_morale === true) {
            let responseSiren = await fetch(
              `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siren=${data.representants[0].siren}`,
              {
                mode: "cors",
                method: "GET",
                headers: headers,
              },
            )
            let dataSiren = await responseSiren.json()
            this.company = {
              postalCode: this.pappersInformations[0].siege.code_postal,
              address: this.pappersInformations[0].siege.adresse_ligne_1,
              city: this.pappersInformations[0].siege.ville,
              nameCompany: this.pappersInformations[0].nom_entreprise,
              siret: siret,
              codeNaf: this.pappersInformations[0].siege.code_naf,
              libelleNaf: this.pappersInformations[0].siege.libelle_code_naf,
              representants: [],
            }

            dataSiren.representants.forEach(ceo => {
              if (ceo.qualite === "Gérant") {
                this.company.representants.push(ceo)
              }
            })
          } else {
            this.company = {
              postalCode: this.pappersInformations[0].siege.code_postal,
              address: this.pappersInformations[0].siege.adresse_ligne_1,
              city: this.pappersInformations[0].siege.ville,
              nameCompany: this.pappersInformations[0].nom_entreprise,
              siret: siret,
              codeNaf: this.pappersInformations[0].siege.code_naf,
              libelleNaf: this.pappersInformations[0].siege.libelle_code_naf,
              representants: this.pappersInformations[0].representants,
            }
          }
          this.dataPappers = true
          console.log(data)
          console.log(this.pappersInformations[0].representants)
        }
        this.loadingData = false
      } catch (e) {
        console.log(e)
      }
    },

    async addCustomerPro() {
      let agencyId = this.$store.state.user.agency_id
      let typo = "client"

      console.log("Donnée de l'entreprise : ", this.company)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional?idAgency=${agencyId}&postalCode=${this.company.postalCode}&city=${this.company.city}
            &address=${this.company.address}&nameCompany=${this.company.nameCompany}&phone=${this.company.phone}&email=${this.company.email}
            &codeNaf=${this.company.codeNaf}&siret=${this.company.siret}&libelleNaf=${this.company.libelleNaf}&typology=${typo}`,
          {
            mode: "no-cors",
            method: "POST",
            headers: headers,
          },
        )
        console.log(response)
        if (response.status === 400) {
          console.log("Eurreur dans l'envoie !")
        } else {
          console.log("Envoyer ! ", response)
          this.$toast.success(`Le client ${this.company.nameCompany} à bien été ajouté.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error(`Erreur lors de l'envoie.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }

      for (let i = 0; i < this.company.representants.length; i++) {
        console.log("i : ", i)
        this.addCustomerProLeader(i)
      }
    },

    async addCustomerProLeader(index) {
      console.log("representant : ", this.company.representants[index].nom)
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/customer_professional_leader.php?idAgency=${agencyId}&firstname=${this.company.representants[index].prenom_usuel}
          &lastname=${this.company.representants[index].nom}&gender=${this.company.representants[index].sexe}&quality=${this.company.representants[index].qualite}
          &address=${this.company.representants[index].adresse_ligne_2}&postalCode=${this.company.representants[index].code_postal}&city=${this.company.representants[index].ville}
          &cityBorn=${this.company.representants[index].ville_de_naissance}&country=${this.company.representants[index].pays}&countryBorn=${this.company.representants[index].pays_de_naissance}`,
          {
            mode: "no-cors",
            method: "POST",
            headers: headers,
          },
        )
        console.log(response)
        if (response.status === 400) {
          console.log("Eurreur dans l'envoie !")
        } else {
          this.company = {
            postalCode: null,
            address: null,
            city: null,
            nameCompany: null,
            siret: null,
            codeNaf: null,
            libelleNaf: null,
            email: null,
            phone: null,
            representants: [],
          }
        }
      } catch (e) {
        console.log(e)
        this.$toast.error(`Erreur lors de l'envoie.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
      window.location.reload()
    },
  },
})
</script>
<style>
.no-padding {
  padding: 0 !important;
}
</style>
